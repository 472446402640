/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-May-2018, 09:55:23
    Author     : sgavin
*/
/* @popup-background: @brand-primary;
@popup-title: @gray-lighter;
@popup-font: @brand-tertiary;
@popup-border: @gray-light; */
.tutorial,
.hw-tooltip {
  background-color: #009CCA;
  border-width: 1px;
  width: 200px;
  opacity: 0;
  display: none;
  color: #ffffff;
  border-color: #0384aa;
  border-style: solid;
  position: absolute;
  transition: opacity 0.5s ease-in;
  padding: 8px;
}
.tutorial#codes-tooltip,
.hw-tooltip#codes-tooltip {
  z-index: 2500;
}
.tutorial h1,
.hw-tooltip h1 {
  color: #374A5E;
  margin-top: 0px;
  font-size: 15px;
}
.tutorial .arrow,
.hw-tooltip .arrow {
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-color: #009CCA;
}
.tutorial[x-placement^="top"] .arrow,
.hw-tooltip[x-placement^="top"] .arrow {
  border-width: 10px 10px 0 10px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -10px;
}
.tutorial[x-placement^="right"] .arrow,
.hw-tooltip[x-placement^="right"] .arrow {
  border-width: 10px 10px 10px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -10px;
}
.tutorial[x-placement^="bottom"] .arrow,
.hw-tooltip[x-placement^="bottom"] .arrow {
  border-width: 0 10px 10px 10px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: -10px;
}
.tutorial[x-placement^="left"] .arrow,
.hw-tooltip[x-placement^="left"] .arrow {
  border-width: 10px 0 10px 10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -10px;
}
#filtermenu-select-bar.show .tutorial,
#filtermenu-select-bar.show .hw-tooltip {
  display: block;
  opacity: 1;
}
.hw-tooltip.show {
  display: block;
  opacity: 1;
}
