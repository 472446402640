/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 17-May-2018, 09:55:23
    Author     : sgavin
*/

@import "includes/variables.less";
@import "includes/bootstrap/mixins.less";

/* @popup-background: @brand-primary;
@popup-title: @gray-lighter;
@popup-font: @brand-tertiary;
@popup-border: @gray-light; */
@popup-background: @brand-secondary;
@popup-title: #374A5E;
@popup-font: #ffffff;
@popup-border: @brand-tertiary;

.tutorial, .hw-tooltip {    
    background-color: @popup-background;
    border-width: 1px;
    width: 200px;
    opacity: 0;
    display: none;
    color: @popup-font;
    border-color: @popup-border;
    border-style: solid;
    position: absolute;
    transition: opacity 0.5s ease-in;

    &#codes-tooltip {
        z-index: 2500;
    }
    h1 {
        color: @popup-title;
        margin-top: 0px;
        font-size: 15px;
    }
    padding: 8px;

    &.codes {
    }
    .arrow {
        width: 0; 
        height: 0; 
        position: absolute;
        border-style: solid;
        border-color: @popup-background;
    }
    &[x-placement^="top"] .arrow {
        border-width: 10px 10px 0 10px;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        bottom: -10px;
    }
    &[x-placement^="right"] .arrow {
        border-width: 10px 10px 10px 0;
        border-left-color: transparent;
        border-top-color: transparent;
        border-bottom-color: transparent;
        left: -10px;
    }
    &[x-placement^="bottom"] .arrow {
        border-width: 0 10px 10px 10px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        top: -10px;
    }
    &[x-placement^="left"] .arrow {
        border-width: 10px 0 10px 10px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        right: -10px;
    }
}

#filtermenu-select-bar.show {
    .tutorial, .hw-tooltip {
        display: block;
        opacity: 1;
        
    } 
}
.hw-tooltip.show {
    display: block;
    opacity: 1;
} 
